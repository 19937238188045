<!-- 编辑弹窗 -->
<template>
  <div>
    <a-modal
      :width="600"
      :visible="visible"
      :confirm-loading="loading"
      :title="isUpdate ? '修改广告' : '新建广告'"
      :body-style="{ paddingBottom: '8px' }"
      @update:visible="updateVisible"
      @ok="save"
    >
      <a-form
        ref="form"
        :model="form"
        :rules="rules"
        :label-col="{ md: { span: 5 }, sm: { span: 24 } }"
        :wrapper-col="{ md: { span: 19 }, sm: { span: 24 } }"
      >
        <a-row :gutter="16">
          <a-col :sm="24" :xs="24">
            <a-form-item label="广告名称:" name="activityName">
              <a-input
                v-model:value="form.activityName"
                placeholder="请输入广告名称"
                allow-clear
              />
            </a-form-item>
            <a-form-item label="广告规则:" name="adCount">
              <a-input-number
                v-model:value="form.adCount"
                placeholder="请输入广告规则"
                allow-clear
                style="width:100%"
              />
            </a-form-item>
            <a-form-item label="广告主图:" name="activityMainPic">
              <a-upload
                list-type="picture-card"
                v-model:file-list="activityMainPicList"
                @preview="handlePreview"
                :customRequest="
                  ({ file }) => uploadFile(file, 'activityMainPic')
                "
                :remove="removeFile"
              >
                <div v-if="activityMainPicList.length < 1">
                  <plus-outlined />
                  <div class="ant-upload-text">上传</div>
                </div>
              </a-upload>
              <a-modal
                :visible="previewVisible"
                :footer="null"
                :bodyStyle="{ paddingTop: '50px' }"
                @cancel="previewVisible = false"
              >
                <img alt="example" style="width: 100%" :src="previewImage" />
              </a-modal>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import * as activityInfoApi from '@/api/activity/activityInfo.js'
// import * as dictApi from '@/api/dict/dictData.js'
// import * as corpApi from '@/api/corp/corp.js'
// import * as activityContentApi from '@/api/activityContent/activityContent.js'
// import * as activityNoticeApi from '@/api/activityNotice/index.js'
// import * as activityUserRegApi from '@/api/activityUserReg/activityUserReg.js'
import setting from '@/config/setting'

import { PlusOutlined } from '@ant-design/icons-vue'
function getBase64(pointPhoto) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(pointPhoto)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}
export default {
  name: 'activityInfoEdit',
  emits: ['done', 'update:visible'],
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object
  },
  components: {
    PlusOutlined
  },
  data() {
    return {
      // 表单数据
      form: Object.assign({}, this.data),
      uploadUrl: setting.uploadUrl,
      // 编辑弹窗表单验证规则
      rules: {
        activityName: [
          {
            required: true,
            message: '请输入广告名称',
            type: 'string',
            validateTrigger: 'blur'
          }
        ]
      },
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,
      activityMainPicList: [],
      previewVisible: false,
      previewImage: ''
    }
  },
  watch: {
    data() {
      if (this.data) {
        this.form = this.data
        this.isUpdate = true
      } else {
        this.form = {}
        this.activityMainPic = ''
        this.isUpdate = false
        this.activityMainPicList = []
      }
      if (this.form.activityMainPic) {
        this.activityMainPicList[0] = {
          uid: '-10',
          name: 'image.png',
          status: 'done',
          url: this.form.activityMainPic
        }
      }
    }
  },
  mounted() {
    // this.queryActivityState()
    // this.queryActivityType()
    // this.activityStore()
  },
  methods: {
    removeFile(file) {
      const uid = file.uid
      this.$http
        .delete('/file/remove', {
          path: file.url
        })
        .then((res) => {
          if (res.data.code === 0) {
            if (uid === '-10') {
              this.activityMainPicList = []
              this.form.activityMainPic = ''
            } else {
              if (this.activityPicsList.length > 0) {
                const picsArr = this.activityPicsList.map((item) => {
                  return item.url
                })
                this.form.activityPics = this.arrToStr(picsArr)
              } else {
                this.form.activityPics = ''
              }
            }
            this.$message.success(res.data.msg)
          } else {
            this.$message.error(res.data.msg)
          }
        })
        .catch((err) => {
          this.$message.error(err)
        })
    },
    uploadFile(file, name) {
      console.log('fileList', this.activityPicsList)
      const formData = new FormData()
      formData.append('file', file)
      const uid = file.uid
      const hide = this.$message.loading('上传中..', 0)
      this.$http
        .post(this.uploadUrl, formData)
        .then((res) => {
          hide()
          if (res.data.code === 0) {
            if (name === 'activityMainPic') {
              this.activityMainPicList = [
                {
                  uid: uid,
                  name: 'image.png',
                  status: 'done',
                  url: res.data.location
                }
              ]
              this.form[name] = res.data.location
            }
          } else {
            this.$message.error(res.data.msg)
          }
        })
        .catch((e) => {
          hide()
          this.$message.error(e.message)
        })
      return false
    },
    // 数组转字符串方法
    arrToStr(arr) {
      if (arr.length > 0) {
        const str = arr.join(',')
        return str
      }
    },
    // 字符串转数组方法
    strToArr(str) {
      if (str) {
        return str.split(',')
      }
    },
    async handlePreview(pointPhoto) {
      if (!pointPhoto.url && !pointPhoto.preview) {
        pointPhoto.preview = await getBase64(pointPhoto.originFileObj)
      }
      this.previewImage = pointPhoto.url || pointPhoto.preview
      this.previewVisible = true
    },
    handleCancel() {
      this.previewVisible = false
    },
    /* 保存编辑 */
    save() {
      this.$refs.form
        .validate()
        .then(() => {
          this.loading = true
          if (this.form.manageIdArr && this.form.manageIdArr.length > 0) {
            this.form.manageId = this.arrToStr(this.form.manageIdArr)
          } else {
            this.form.manageId = ''
          }
          activityInfoApi
            .save(this.form, this.isUpdate)
            .then((res) => {
              this.loading = false
              if (res.code === 0) {
                this.$message.success(res.msg)
                // this.resetForm()
                if (!this.isUpdate) {
                  this.resetForm()
                }
                this.updateVisible(false)
                this.$emit('done')
              } else {
                this.$message.error(res.msg)
              }
            })
            .catch((e) => {
              this.loading = false
              this.$message.error(e.message)
            })
        })
        .catch(() => {})
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value)
    }
  }
}
</script>

<style lang="less" scoped>
.flex-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.author-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #d6d7d9;
  margin: 20px 0;
  padding: 10px 20px;

  .author-content {
    width: 800px;

    .author-item {
      display: flex;
      width: 100%;
      margin-bottom: 10px;

      .value {
        width: 80%;
        white-space: wrap;
        color: #9b9b9b;
      }
    }
  }

  .title {
    display: inline-block;
    width: 40px;
    margin-right: 5px;
  }
}
</style>
