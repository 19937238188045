<!-- 详情弹窗 -->
<template>
  <a-modal
    title="详情"
    :width="600"
    :footer="null"
    :visible="visible"
    @update:visible="updateVisible"
    @cancel="modalCancel"
  >
    <a-form
      class="ud-form-detail"
      :label-col="{ sm: { span: 8 }, xs: { span: 8 } }"
      :wrapper-col="{ sm: { span: 16 }, xs: { span: 16 } }"
    >
      <a-row :gutter="16">
        <a-col :sm="12" :xs="24">
          <a-form-item label="广告名称:">
            <div class="ud-text-secondary">
              {{ data.activityName }}
            </div>
          </a-form-item>

          <a-form-item label="广告主图:">
            <div class="ud-text-secondary">
              <img class="photo" :src="data.activityMainPic" width="100" />
            </div>
          </a-form-item>
        </a-col>
        <a-col :sm="12" :xs="24">
          <a-form-item label="广告规则:">
            <div class="ud-text-secondary">
              {{ data.adCount }}
            </div>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </a-modal>
</template>

<script>
import * as activityContentApi from '@/api/activity/activityContent.js'
export default {
  name: 'OperRecordDetail',
  emits: ['update:visible'],
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 数据
    data: Object
  },
  data() {
    return {
      form: Object.assign({}, this.data),
      activityContent: null
    }
  },
  watch: {
    data() {
      if (this.data) {
        this.form = this.data
        this.getActivityContent(this.form.activityId)
      }
    }
  },
  methods: {
    // 获取富文本活动内容
    getActivityContent(id) {
      activityContentApi
        .info(id)
        .then((res) => {
          if (res.code === 0) {
            // console.log(res.data)
            this.activityContent = res.data.activityContent
            // this.$message.success(res.msg)
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value)
    }
  }
}
</script>

<style scoped>
.photo {
  width: 200px;
  height: 100px;
  margin: 20px 0;
}
.ud-text-secondary {
  line-height: 32px;
}
</style>
